import * as React from 'react';
import Routes from 'routes';
import 'translations';
import { ScrollToTop } from 'components';
import { ConfigProvider } from 'antd';
import appTheme from 'theme/theme';
import { AuthContextProvider } from 'contexts/AuthContext';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import * as Userpilot from 'tools/Userpilot';
import { useLocation } from 'react-router';

export const queryCache = new QueryCache();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retryOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 1000 * 60 * 60 * 24,
    },
    mutations: {
      retry: false,
    },
  },
  queryCache,
});

export default function App() {
  const location = useLocation();

  React.useEffect(() => {
    Userpilot.reload();
  }, [location]);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <ConfigProvider {...appTheme}>
          <ScrollToTop />
          <Routes />
        </ConfigProvider>
      </AuthContextProvider>
    </QueryClientProvider>
  );
}
