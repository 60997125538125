import { useState } from 'react';
import { useHistory } from 'react-router';
import { useAuthContext } from 'contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import AuthService from 'services/api/AuthService';
import { useMutation, useQuery } from '@tanstack/react-query';
import { UserInfos } from 'types/UserInfos';
import BaseService from 'services/api/BaseService';
import { AuthUrls, UserUrls } from 'services/api/urls';
import { User } from 'types/User';
import { message, notification } from 'antd';
import * as Userpilot from 'tools/Userpilot';

export const usePasswordLoginUser = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const history = useHistory();
  const { login } = useAuthContext();
  const { t } = useTranslation();

  const mutation = async (email: string, password: string) => {
    setLoading(true);
    return AuthService.password_login({
      email,
      password,
    })
      .then(async (res: any) => {
        const data = await res.json();
        if (!data?.error && (res.status === 200 || res.status === 201)) {
          Userpilot.identify(data.user);

          login({
            user: data.user,
            token: data.jwt,
          });
          history.push('/?redirect=first');
        } else {
          setError(t('login:login_error'));
        }
      })
      .catch(() => {
        setError(t('global:internet_connexion_error'));
      })
      .finally(() => setLoading(false));
  };
  return {
    loading,
    error,
    mutation,
  };
};

export const usePasswordMutation = () => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const { t } = useTranslation();

  const init = async (email: string) => {
    setLoading(true);
    return AuthService.reset_password_init(email)
      .then(async (res: any) => {
        const data = await res.json();
        if (!data?.error && (res.status === 200 || res.status === 201)) {
          setSuccess(true);
          setError(undefined);
        } else {
          setError(
            data.message ?? data.error.message ?? t('reset_password:error'),
          );
        }
      })
      .catch(() => {
        setError(t('global:internet_connexion_error'));
      })
      .finally(() => setLoading(false));
  };

  const resetPassword = async (
    newPassword: string,
    email: string,
    token: string,
  ) => {
    setLoading(true);
    return AuthService.reset_password({
      password: newPassword,
      email,
      token,
    })
      .then(async (res: any) => {
        const data = await res.json();
        if (!data?.error && (res.status === 200 || res.status === 201)) {
          setSuccess(true);
          setError(undefined);
        } else {
          setError(
            data.message ?? data.error.message ?? t('reset_password:error'),
          );
        }
      })
      .catch(() => {
        setError(t('global:internet_connexion_error'));
      })
      .finally(() => setLoading(false));
  };

  const createPassword = async (
    password: string,
    email: string,
    token: string,
  ) => {
    setLoading(true);
    return AuthService.create_password({
      password,
      token,
      email,
    })
      .then(async (res: any) => {
        const data = await res.json();
        if (!data?.error && (res.status === 200 || res.status === 201)) {
          setSuccess(true);
          setError(undefined);
        } else {
          setError(
            data.message ?? data.error.message ?? t('first_login:error'),
          );
        }
      })
      .catch(() => {
        setError(t('global:internet_connexion_error'));
      })
      .finally(() => setLoading(false));
  };

  const updatePassword = async (
    old_password: string,
    new_password: string,
    onSuccess: () => void,
  ) => {
    setLoading(true);
    return AuthService.change_password({
      old_password,
      new_password,
    })
      .then(async (res: any) => {
        const data = await res.json();
        if (!data?.error && (res.status === 200 || res.status === 201)) {
          onSuccess();
          setError(undefined);
        } else {
          setError(
            data.message ?? data.error.message ?? t('first_login:error'),
          );
        }
      })
      .catch(() => {
        setError(t('global:internet_connexion_error'));
      })
      .finally(() => setLoading(false));
  };

  return {
    loading,
    error,
    success,
    init,
    resetPassword,
    createPassword,
    updatePassword,
  };
};

export const useUserInfos = () => {
  const { t } = useTranslation();

  return useQuery<UserInfos, Error>({
    queryKey: [UserUrls.GET_ACCOUNT_INFO],
    queryFn: async () => {
      let data: any;
      try {
        const response = await BaseService.getRequest(
          UserUrls.GET_ACCOUNT_INFO,
          true,
        );
        data = await response.json();
        if ([200, 201].includes(response.status)) {
          return data;
        }
      } catch (e: any) {
        console.warn('Get account info error', e);
        throw new Error(t('global:internet_connexion_error'));
      }
      throw new Error(data?.message || t('global:internet_connexion_error'));
    },
  });
};

export const useTokenStatus = (email: string, enabled: boolean) => {
  const { t } = useTranslation();
  const history = useHistory();

  return useQuery<{ status: 'expired' | 'valid' }, Error>({
    queryKey: [AuthUrls.CREATE_PASSWORD_TOKEN_STATUS, email],
    queryFn: async () => {
      let data: any;
      try {
        const response = await BaseService.postRequest(
          AuthUrls.CREATE_PASSWORD_TOKEN_STATUS,
          { email },
          false,
        );
        data = await response.json();
        if ([200, 201].includes(response.status)) {
          if (data.status === 'expired') {
            history.replace(
              `/lien-non-valide?email=${encodeURIComponent(email)}`,
            );
          }
          return data;
        }
      } catch (e: any) {
        throw new Error(t('global:internet_connexion_error'));
      }
      throw new Error(data?.message || t('global:internet_connexion_error'));
    },
    gcTime: 0,
    enabled,
  });
};

export const useChangeAvatar = () => {
  const { t } = useTranslation('global');
  const { login, auth } = useAuthContext();

  return useMutation<User, Error, File>({
    mutationFn: async (file) => {
      if (file.size > 1024 * 1024 * 5) {
        throw new Error(t('max_file', { size: '5Mo' }));
      }
      const formData = new FormData();
      formData.append('image', file);
      let data;
      try {
        const response = await BaseService.putFileRequest(
          UserUrls.CHANGE_AVATAR,
          formData,
          true,
        );
        data = await response.json();
        if ([200, 201].includes(response.status) && auth) {
          login({
            user: data,
            token: auth.token,
          });
          message.success(t('avatar:avatar_change_success'));
          return data;
        }
      } catch (e: any) {
        throw new Error(t('internet_connexion_error'));
      }
      throw new Error(data?.message || t('internet_connexion_error'));
    },
    onError: (error) => {
      notification.error({
        message: t(error.message),
      });
    },
  });
};

export const useResendFirstLoginEmail = () => {
  const { t } = useTranslation('global');

  return useMutation<User, Error, { email: string }>({
    mutationFn: async (payload) => {
      let data;
      try {
        const response = await BaseService.postRequest(
          UserUrls.RESEND_FIRST_EMAIL,
          payload,
          false,
        );
        data = await response.json();
        if ([200, 201].includes(response.status)) {
          return data;
        }
      } catch (e: any) {
        console.error('e', e);
        throw new Error(t('internet_connexion_error'));
      }
      throw new Error(data?.message || t('internet_connexion_error'));
    },
    onError: (error) => {
      notification.error({
        message: t(error.message),
      });
    },
  });
};
