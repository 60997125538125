import { ConfigProviderProps } from 'antd';
import frFR from 'antd/locale/fr_FR';
import 'theme/overrides.scss';

import dayjs from 'dayjs';

import 'dayjs/locale/fr';

dayjs.locale('fr');

const appTheme: ConfigProviderProps = {
  locale: frFR,
  button: {
    className: 'button-override text-sm',
    classNames: {
      icon: 'mt-1',
    },
  },
  input: {
    className: 'input-override',
  },
  textArea: {
    className: 'textarea-override',
  },
  theme: {
    cssVar: { key: 'app' },
    token: {
      fontFamily: 'Fieldwork, sans-serif',
      colorPrimary: '0070BA',
      colorText: '#3A3A3A',
      colorSuccess: '#20A76A',
      colorSuccessBg: '#EBF4EE',
      colorSuccessBorder: '#EBF4EE',
      colorPrimaryText: '#FFFFFF',
      colorBgLayout: '#0070BA',
      colorPrimaryHover: '#005892',
      colorPrimaryTextHover: '#005892',
      colorBgContainer: '#FFFFFF',
      colorTextDisabled: '#C6C2BE',
      borderRadius: 6,
      fontSizeHeading1: 48,
      lineHeightHeading1: 1.16,
      fontSizeHeading2: 28,
      fontSizeHeading4: 24,
      fontSizeHeading5: 20,
      lineHeightHeading5: 1,
      lineHeight: 1.57,
      boxShadow: '0px 0px 5px 0px rgba(58, 58, 58, 0.10)',
    },
  },
};

export default appTheme;
